<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.push({
            name: 'PerformanceSettings',
            query: { activeTab: 'Performance Templates' },
          })
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 230px; margin-top: 0.1rem">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          Template Settings
        </h1>
      </div>
      <div class="w-7/12" style="margin-top: 1.2rem">
        <Breadcrumb
          :items="breadcrumbs"
          @onClick="handleBreadcrumbClick($event)"
        />
      </div>
    </div>

    <div style="height: 100%" class="mt-28" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div class="px-3" v-else>
      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <card class="p-4 mt-3">
          <div class="w-full flex flex-col">
            <p class="my-4 text-lg font-bold">Performance Agreement Template</p>

            <div class="grid grid-cols-12 gap-6 my-2">
              <div class="col-span-4">
                <c-text
                  placeholder="--Enter Name--"
                  variant="w-full"
                  label="Title"
                  :rules="['required']"
                  v-model="performanceTemplate.agreement_title"
                />
              </div>

              <div class="col-span-4">
                <c-text
                  placeholder="--Enter Name--"
                  variant="w-full"
                  label="Title Description"
                  :rules="['required']"
                  v-model="performanceTemplate.agreement_description"
                />
              </div>

              <div class="col-span-4">
                <CSelect
                  placeholder="Enter"
                  :options="optionsPeriod"
                  v-model="frequency"
                  label="Appraisal Frequency"
                  variant="w-full h-10"
                  class="h-10 mr-2"
                  :rules="['required']"
                  :disabled="true"
                />
              </div>
            </div>

            <div class="grid grid-cols-12 gap-6 mt-4 mb-6">
              <div class="col-span-6">
                <label class="date-label"> Cycle Start Date </label>
                <div class="date-flex bg-disabled">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="cycleStartDate"
                    :disabled="true"
                  />
                </div>
              </div>

              <div class="col-span-6">
                <label class="date-label"> Cycle End Date </label>
                <div class="date-flex bg-disabled">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="cycleEndDate"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>

            <div class="w-full flex flex-col">
              <ValidationObserver rules="required" v-slot="{ handleSubmit }">
                <p class="text-base font-semibold mt-4 mb-2">Appraisal</p>

                <div
                  class="grid grid-cols-12 gap-6 mt-4 mb-7"
                  v-for="(n, index) in appraisal_cycles"
                  :key="index"
                >
                  <div
                    class="col-span-4"
                    v-if="appraisal_cycles[index].name !== null"
                  >
                    <div class="flex -mt-1 mb-1">
                      <label class="date-label"> {{appraisal_cycles[index].label === null ? 'Single Appraisal' : appraisal_cycles[index].label}} </label>

                    </div>
                    <c-text
                      placeholder="--Enter Name--"
                      variant="w-full mt-1"
                      class="label"
                      v-model="appraisal_cycles[index].name"
                      :label="appraisal_cycles[index].label"
                      :rules="['required']"
                      :disabled="true"
                    />
                  </div>

                  <div :class="frequency === '1' ? 'col-span-4' : 'col-span-4'">
                    <label class="date-label"> Appraisal Starts </label>
                    <div class="date-flex bg-disabled">
                      <datepicker
                        placeholder="--Select Day & Month--"
                        style="width: 100%; outline: none"
                        input-class="date-input"
                        format="dd/MMMM"
                        v-model="appraisal_cycles[index].appraisal_starts"
                        :disabled="true"
                      />
                    </div>
                  </div>

                  <div :class="frequency === '1' ? 'col-span-4' : 'col-span-4'">
                    <label class="date-label"> Appraisal Ends </label>
                    <div class="date-flex bg-disabled">
                      <datepicker
                        placeholder="--Select Day & Month--"
                        style="width: 100%; outline: none"
                        input-class="date-input"
                        format="dd/MMMM"
                        v-model="appraisal_cycles[index].appraisal_ends"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>

                <div
                  class="text-flame font-semibold flex w-full mt-6 p-2 text-base cursor-pointer"
                  v-if="frequency === '5'"
                  @click="handleSubmit(addAppraisal)"
                >
                  <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                  Add Another Appraisal
                </div>
              </ValidationObserver>
            </div>
          </div>
        </card>

        <card class="p-4 mt-3">
          <div class="w-full flex flex-col">
            <p class="my-4 text-lg font-bold">KPA Types</p>
            <div class="flex w-1/2 my-3">
              <c-select
                placeholder="--Select KPA Types--"
                :options="optionsKPA"
                variant="w-full"
                class="w-full"
                label="Add KPA Types"
                v-model="selectedKPA"
                :rules="['required']"
              />
            </div>
            <div class="flex w-full flex-wrap">
              <MultiSelectTag
                bg-color="#F7F7FF"
                v-for="(t, index) in selectedKPATags"
                size="xms"
                :key="index"
                @close="deleteSelectedKPA(t.id)"
              >
                <div
                  class="text-jet flex flex-grow"
                  style="font-size: 14px; margin-right: 10px"
                >
                  {{ t.name }}
                </div>
              </MultiSelectTag>
            </div>
          </div>
        </card>

        <card class="p-4 mt-3" v-feature-request>
          <div class="w-full flex flex-col">
            <p class="my-4 text-lg font-bold">
              Performance Moderation (Optional)
            </p>
            <div class="flex w-full my-3">
              <checkbox
                checkbox-size="height:16px; width:16px; margin-top: 3px;"
                label="Enable moderation after appraisal"
                v-model="performanceTemplate.enable_moderation"
              />
            </div>

            <div
              class="grid grid-cols-12 gap-6 my-4"
              v-if="performanceTemplate.enable_moderation"
            >
              <div class="col-span-6">
                <label class="date-label"> Functional Moderation Starts </label>
                <div class="date-flex">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="functionalStart"
                    @selected="moderationStart($event)"
                  />
                </div>
              </div>

              <div class="col-span-6">
                <label class="date-label"> Functional Moderation Ends </label>
                <div class="date-flex">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="functionalEnd"
                    @selected="moderationEnd($event)"
                  />
                </div>
              </div>

              <div class="col-span-6">
                <label class="date-label"> Final/CEO Moderation Starts </label>
                <div class="date-flex">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="ceoStart"
                    @selected="moderationStart($event)"
                  />
                </div>
              </div>

              <div class="col-span-6">
                <label class="date-label"> Final/CEO Moderation Ends </label>
                <div class="date-flex">
                  <datepicker
                    placeholder="--Select Day & Month--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="dd/MMMM"
                    v-model="ceoEnd"
                    @selected="moderationEnd($event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </card>

        <card class="p-4 mt-3">
          <div class="w-full flex flex-col">
            <p class="mt-4 text-lg font-bold">Apply Template To Employees</p>
            <div class="flex flex-col w-full mt-3">
              <radio-button
                :options="template_apply"
                :col-span="`col-span-12
              ${
                applyTemplate === 'selected'
                  ? 'border-t border-r border-l'
                  : 'border'
              }
              p-4 border-dashed rounded-sm`"
                class="text-darkPurple"
                row-gap="gap-y-2 gap-x-8"
                space-between="mr-3"
                v-model="applyTemplate"
                @change="setApply"
              />
            </div>

            <div
              class="flex flex-col p-4 border-r border-l border-b border-dashed"
              v-if="applyTemplate === 'selected'"
            >
              <div class="w-1/2">
                <div class="flex -mt-1">
                  <span class="text-sm font-semibold flex flex-grow">
                    Selected Location(s)
                  </span>
                  <checkbox
                    checkbox-size="height:16px; width:16px; margin-top: 3px;"
                    label="All Locations"
                    v-model="applyToAllLocation"
                  />
                </div>
                <c-select
                  placeholder="--Select Applicable Locations--"
                  :options="locations"
                  variant="w-full"
                  class="w-full label"
                  label="Selected Location(s)"
                  :disabled="applyToAllLocation"
                  v-model="selectedLocation"
                  :rules="!applyToAllLocation ? ['required'] : []"
                />

                <Alert
                  message="Applying to employees in all locations !"
                  variant="primary"
                  :time="2"
                  style="
                    position: relative;
                    right: 0;
                    z-index: 0000;
                    top: 8px;
                    font-size: 14px;
                    width: 100%;
                    box-shadow: none;
                    background: rgba(233, 147, 35, 0.15);
                    color: #333333;
                    border: 1px solid rgba(233, 147, 35, 1);
                  "
                  v-if="applyToAllLocation"
                />

                <div
                  class="flex w-full flex-wrap mt-3"
                  v-if="!applyToAllLocation"
                >
                  <MultiSelectTag
                    bg-color="#F7F7FF"
                    size="xms"
                    v-for="(l, index) in selectedLocationTags"
                    :key="index"
                    @close="deleteSelectedLocation(l.id)"
                  >
                    <div
                      class="text-jet flex flex-grow"
                      style="font-size: 14px; margin-right: 10px"
                    >
                      {{ l.name }}
                    </div>
                  </MultiSelectTag>
                </div>
              </div>

              <div class="w-full my-4 flex">
                <div
                  style="width: 33%; margin-right: 2%"
                  class="p-4 border flex flex-col rounded-sm"
                >
                  <p class="mt-1 mb-3 text-base font-semibold">
                    Employees By Function
                  </p>
                  <div class="flex mt-3">
                    <span class="text-sm font-semibold flex flex-grow">
                      Functions
                    </span>
                    <checkbox
                      checkbox-size="height:16px; width:16px; margin-top: 3px;"
                      label="All Functions"
                      v-model="applyToAllFunction"
                    />
                  </div>
                  <c-select
                    placeholder="--Select Applicable Functions--"
                    :options="functions"
                    variant="w-full"
                    class="w-full label"
                    :disabled="applyToAllFunction"
                    @input="selectedFunction($event)"
                    v-model="selectedFunctionResponse"
                    :rules="!applyToAllFunction ? ['required'] : []"
                  />

                  <Alert
                    message="Applying to employees in all functions"
                    variant="primary"
                    :time="2"
                    style="
                      position: relative;
                      right: 0;
                      z-index: 0000;
                      top: 8px;
                      font-size: 14px;
                      width: 100%;
                      box-shadow: none;
                      background: rgba(233, 147, 35, 0.15);
                      color: #333333;
                      border: 1px solid rgba(233, 147, 35, 1);
                    "
                    v-if="applyToAllFunction"
                  />

                  <div
                    class="flex w-full flex-wrap mt-3"
                    v-if="!applyToAllFunction"
                  >
                    <MultiSelectTag
                      bg-color="#F7F7FF"
                      size="xms"
                      v-for="(functionTag, index) in selectedFunctionTags"
                      :key="index"
                      @close="deleteSelectedFunction(functionTag.id)"
                    >
                      <div
                        class="text-jet flex flex-grow"
                        style="font-size: 14px; margin-right: 10px"
                      >
                        {{ functionTag.name }}
                      </div>
                    </MultiSelectTag>
                  </div>
                </div>

                <div
                  style="width: 33%; margin-right: 2%"
                  class="p-4 border flex flex-col rounded-sm"
                >
                  <p class="mt-1 mb-3 text-base font-semibold">
                    Employees By Job Level
                  </p>
                  <div class="flex mt-3">
                    <span class="text-sm font-semibold flex flex-grow">
                      Job Levels
                    </span>
                    <checkbox
                      checkbox-size="height:16px; width:16px; margin-top: 3px;"
                      label="All Job Levels"
                      v-model="applyToAllJobLevel"
                    />
                  </div>
                  <c-select
                    placeholder="--Select Applicable Job Levels--"
                    :options="jobLevels"
                    variant="w-full"
                    class="w-full label"
                    :disabled="applyToAllJobLevel"
                    v-model="selectedLevel"
                    :rules="!applyToAllJobLevel ? ['required'] : []"
                  />

                  <Alert
                    message="Applying to employees of all levels"
                    variant="primary"
                    :time="2"
                    style="
                      position: relative;
                      right: 0;
                      z-index: 0000;
                      top: 8px;
                      font-size: 14px;
                      width: 100%;
                      box-shadow: none;
                      background: rgba(233, 147, 35, 0.15);
                      color: #333333;
                      border: 1px solid rgba(233, 147, 35, 1);
                    "
                    v-if="applyToAllJobLevel"
                  />

                  <div
                    class="flex w-full flex-wrap mt-3"
                    v-if="!applyToAllJobLevel"
                  >
                    <MultiSelectTag
                      bg-color="#F7F7FF"
                      size="xms"
                      v-for="(level, index) in selectedLevelTags"
                      :key="index"
                      @close="deleteSelectedLevel(level.id)"
                    >
                      <div
                        class="text-jet flex flex-grow"
                        style="font-size: 14px; margin-right: 10px"
                      >
                        {{ level.name }}
                      </div>
                    </MultiSelectTag>
                  </div>
                </div>

                <div
                  style="width: 33%; margin-right: 0%"
                  class="p-4 border flex flex-col rounded-sm"
                >
                  <p class="mt-1 mb-3 text-base font-semibold">
                    Employees By Designation
                  </p>
                  <div class="flex mt-3">
                    <span class="text-sm font-semibold flex flex-grow">
                      Designations
                    </span>
                    <checkbox
                      checkbox-size="height:16px; width:16px; margin-top: 3px;"
                      label="All Designations"
                      v-if="designations.length > 0"
                      v-model="applyToAllDesignation"
                      :disabled="disableCheckBox"
                    />
                  </div>
                  <c-select
                    placeholder="--Select Applicable Designations--"
                    :options="designations"
                    variant="w-full"
                    class="w-full label"
                    :disabled="applyToAllDesignation"
                    v-model="selectedDesignation"
                    :rules="
                      !applyToAllDesignation && designations.length > 0
                        ? ['required']
                        : []
                    "
                  />
                  <Alert
                    message="Select at least one function to be able to see all applicable designations..."
                    variant="primary"
                    :time="2"
                    style="
                      position: relative;
                      right: 0;
                      z-index: 0000;
                      top: 12px;
                      font-size: 12px;
                      width: 100%;
                      box-shadow: none;
                      background: rgba(234, 60, 83, 0.15);
                      color: #ea3c53;
                    "
                    v-if="
                      designations.length === 0 &&
                      selectedDesignationTags.length === 0
                    "
                  />
                  <div
                    class="flex w-full flex-wrap mt-3"
                    v-if="!applyToAllDesignation"
                  >
                    <MultiSelectTag
                      bg-color="#F7F7FF"
                      size="xms"
                      v-for="(designation, index) in selectedDesignationTags"
                      :key="index"
                      @close="deleteSelectedDesignation(designation.id)"
                    >
                      <div
                        class="text-jet flex flex-grow"
                        style="font-size: 14px; margin-right: 10px"
                      >
                        {{ designation.name }}
                      </div>
                    </MultiSelectTag>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>

        <div class="flex mt-5">
          <div class="flex flex-grow">
            <Button
              class="bg-dynamicBackBtn text-white text-center"
              width="8rem"
              @click="handleSubmit(handleSaveTemplate)"
              :disabled="disableBtn"
            >
              Save
            </Button>
            <Button
              background-color="none"
              class="ml-4 text-darkPurple text-center"
              width="8rem"
              @click="
                $router.push({
                  name: 'PerformanceSettings',
                  query: { activeTab: 'Performance Templates' },
                })
              "
            >
              Cancel
            </Button>
          </div>
          <div class="flex justify-end">
            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
              width="8rem"
              @click="organizeTemplate('draft')"
              v-if="
                performanceTemplate.status === 'draft' &&
                performanceTemplate.agreement_title !== '' &&
                performanceTemplate.agreement_description !== ''
              "
            >
              Save Draft
            </Button>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Datepicker from "vuejs-datepicker";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@/components/Card";
import RadioButton from "@/components/RadioButton";
import Checkbox from "@/components/Checkbox";
import MultiSelectTag from "@/components/MultiSelectTag";
import Button from "@/components/Button";
import paramsMixin from '@/utilities/paramsMixin';
import timeFormatMixin from "@/utilities/timeFormatMixin";

export default {
  name: "TemplateSettings",
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Checkbox,
    MultiSelectTag,
    Breadcrumb,
    Alert,
    Button,
    Card,
    Datepicker,
    RadioButton,
    CSelect,
    CText,
  },

  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Settings",
          href: "Settings",
          id: "Settings",
        },
        {
          disabled: false,
          text: "Performance",
          href: "PerformanceSettings",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Template",
          id: "TemplateSettings",
        },
      ],
      template_apply: [
        {
          name: "Apply to All Employees",
          radioName: "template_right",
          value: "all",
        },
        {
          name: "Apply to Selected Employees",
          radioName: "template_right",
          value: "selected",
        },
      ],
      documentation_apply: [
        {
          name: "Manager to document",
          radioName: "document",
          value: "manager",
        },
        {
          name: "Employee to document",
          radioName: "document",
          value: "employee",
        },
        { name: "Both", radioName: "document", value: "both" },
      ],
      optionsPeriod: [
        { id: 1, name: "Annual" },
        { id: 2, name: "BiAnnual" },
        { id: 4, name: "Quarterly" },
        { id: 5, name: "Custom" },
      ],
      optionsVal: "1",
      BiAppraisal: [
        {
          value: "Mid-Year Review",
          label: "First Appraisal",
        },
        {
          value: "Final Appraisal",
          label: "Final Appraisal",
        },
      ],
      QAppraisal: [
        {
          value: "Q1 Appraisal",
          label: "First Appraisal",
        },
        {
          value: "Q2 Appraisal",
          label: "Second Appraisal",
        },
        {
          value: "Q3 Appraisal",
          label: "Third Appraisal",
        },
        {
          value: "Q4 Appraisal",
          label: "Final Appraisal",
        },
      ],
      disabledDates: {
        from: "",
        to: "", // Disable all dates up to specific date
      },
      disableModerationDates: {
        from: "",
        to: "",
      },
      disableBtn: false,
      appraisalEndDate: "",
      loading: true,
      setModeration: false,
      applyTemplate: "all",
      selectedKPA: "",
      selectedKPATags: [],
      optionsKPA: [],
      kpaTypes: [],
      locations: [],
      selectedLocation: "",
      selectedLocationTags: [],
      jobLevels: [],
      selectedLevel: "",
      selectedLevelTags: [],
      functions: [],
      selectedFunctionResponse: "",
      selectedFunctionTags: [],
      designations: [],
      disableCheckBox: false,
      allDesignations: [],
      filteredDesignations: [],
      selectedDesignation: "",
      selectedDesignationTags: [],
      applyToAllLocation: false,
      applyToAllJobLevel: false,
      applyToAllFunction: false,
      applyToAllDesignation: false,
      performanceTemplate: {
        orgId: null,
        title: "",
        performance_template_status: "draft",
        kpa_types: [],
        appraisalCycleId: "",
        agreement_title: "",
        agreement_description: "",
        status: "draft",
        performance_agreement: {
          title: "",
          appraisal_frequency: "Annual",
          cycle_start_date: "",
          cycle_end_date: "",
          description: "",
          appraisalCycleId: "",
          appraisal_cycles: [],
        },
        enable_moderation: false,
        functional_moderation_start: "",
        functional_moderation_end: "",
        ceo_moderation_start: "",
        ceo_moderation_end: "",
        appliedTemplateTo: {
          applyToAllLocation: true,
          locationIds: [],
          applyToAllJobLevel: true,
          jobLevelIds: [],
          applyToAllFunction: true,
          functionIds: [],
          applyToAllDesignation: true,
          designationIds: [],
        },
      },
      frequency: null,
      payloadFrequency: "",
      appraisal_cycles: [],
      cycleStartDate: "",
      cycleEndDate: "",
      functionalStart: "",
      functionalEnd: "",
      ceoStart: "",
      ceoEnd: "",
      weightRules: [
        "required",
        {
          name: "value",
          rule: (w) => this.validateWeight(w),
        },
      ],
      locationRules: [
        {
          name: "value",
          rule: (l) => this.validateLocation(l),
        },
      ],
    };
  },

  watch: {
    ceoStart(value) {
      this.performanceTemplate.ceo_moderation_start = this.formatDate(value);
    },

    ceoEnd(value) {
      this.performanceTemplate.ceo_moderation_end = this.formatDate(value);
    },

    functionalStart(value) {
      this.performanceTemplate.functional_moderation_start =
        this.formatDate(value);
    },

    functionalEnd(value) {
      this.performanceTemplate.functional_moderation_end =
        this.formatDate(value);
    },

    frequency(value) {
      this.appraisal_cycles = [];
      const payloadCycles =
        this.performanceTemplate.performance_agreement.appraisal_cycles;

      if (value !== "5") {
        for (let i = 0; i < value; i++) {
          this.appraisal_cycles.push({
            name:
              this.payloadFrequency === value
                ? payloadCycles[i].name
                : this.getCycleName(Number(value), i),
            label: this.getCycleLabel(Number(value), i),
            checkName: false,
            appraisal_starts:
              this.payloadFrequency === value
                ? this.setDates(payloadCycles[i].appraisal_starts)
                : "",
            appraisal_ends:
              this.payloadFrequency === value
                ? this.setDates(payloadCycles[i].appraisal_ends)
                : "",
            weight:
              this.payloadFrequency === value ? payloadCycles[i].weight : "",
          });
        }
      } else if (value === "5" && this.payloadFrequency === "5") {
        for (let i = 0; i < payloadCycles.length; i++) {
          this.appraisal_cycles.push({
            name: payloadCycles[i].name,
            label: this.getCycleLabel(Number(value), 0),
            checkName: true,
            appraisal_starts:
              payloadCycles[i].appraisal_starts === ""
                ? ""
                : this.setDates(payloadCycles[i].appraisal_starts),
            appraisal_ends:
              payloadCycles[i].appraisal_ends === ""
                ? ""
                : this.setDates(payloadCycles[i].appraisal_ends),
            weight: payloadCycles[i].weight,
          });
        }
      } else {
        this.appraisal_cycles.push({
          name: "First Appraisal",
          label: this.getCycleLabel(Number(value), 0),
          checkName: true,
          appraisal_starts: "",
          appraisal_ends: "",
          weight: "",
        });
      }
    },

    selectedKPA() {
      this.kpaTypes.filter((kpa) => {
        if (kpa.id === this.selectedKPA) {
          this.selectedKPATags.push({
            id: kpa.id,
            name: kpa.name,
          });
        }
        return {};
      });

      const disableIndex = this.optionsKPA
        .map((item) => item.id)
        .indexOf(this.selectedKPA);

      document
        .querySelectorAll("select")[1]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    applyToAllLocation(allLocation) {
      if (allLocation) {
        this.selectedLocation = "";
        this.selectedLocationTags = [];
        this.getLocation();
        this.checkTemplateEmployeeApplication();
      }

      this.performanceTemplate.appliedTemplateTo.applyToAllLocation =
        allLocation;
    },

    selectedLocation() {
      this.locations.filter((location) => {
        if (location.id === this.selectedLocation) {
          this.selectedLocationTags.push({
            id: location.id,
            name: location.name,
          });
        }
        return {};
      });

      const disableIndex = this.locations
        .map((item) => item.id)
        .indexOf(this.selectedLocation);

      document
        .querySelectorAll("select")[2]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    applyToAllJobLevel(allLevels) {
      if (allLevels) {
        this.selectedLevel = "";
        this.selectedLevelTags = [];
        this.getJobLevels();
        this.checkTemplateEmployeeApplication();
      }

      this.performanceTemplate.appliedTemplateTo.applyToAllJobLevel = allLevels;
    },

    selectedLevel() {
      this.jobLevels.filter((level) => {
        if (level.id === this.selectedLevel) {
          this.selectedLevelTags.push({
            id: level.id,
            name: level.name,
          });
        }
        return {};
      });

      const disableIndex = this.jobLevels
        .map((item) => item.id)
        .indexOf(this.selectedLevel);

      document
        .querySelectorAll("select")[4]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },

    applyToAllFunction(allFunctions) {
      if (allFunctions) {
        this.selectedFunction = "";
        this.selectedFunctionTags = [];
        this.functions = [];
        this.getFunctions();
        this.getDesignations("");
        this.checkTemplateEmployeeApplication();
      } else {
        this.designations = [];
      }

      this.performanceTemplate.appliedTemplateTo.applyToAllFunction =
        allFunctions;

      this.disableCheckBox = false;
    },

    selectedFunctionResponse() {
      this.functions.filter((f) => {
        if (f.id === this.selectedFunctionResponse) {
          this.selectedFunctionTags.push({
            id: f.id,
            name: f.name,
          });
        }
        return {};
      });

      const disableIndex = this.functions
        .map((item) => item.id)
        .indexOf(this.selectedFunctionResponse);

      document
        .querySelectorAll("select")[3]
        .options[disableIndex + 1].setAttribute("disabled", true);

      if (this.selectedFunctionResponse !== "") {
        this.applyToAllDesignation = false;
        this.disableCheckBox = true;
      }
    },

    applyToAllDesignation(allDesignations) {
      if (allDesignations) {
        this.selectedDesignation = "";
        this.selectedDesignationTags = [];
        this.checkTemplateEmployeeApplication();
      }

      this.performanceTemplate.appliedTemplateTo.applyToAllDesignation =
        allDesignations;
    },

    selectedDesignation() {
      this.designations.filter((designation) => {
        if (designation.id === this.selectedDesignation) {
          this.selectedDesignationTags.push({
            id: designation.id,
            name: designation.name,
          });
        }
        return {};
      });

      const disableIndex = this.designations
        .map((item) => item.id)
        .indexOf(this.selectedDesignation);

      document
        .querySelectorAll("select")[5]
        .options[disableIndex + 1].setAttribute("disabled", true);
    },
  },

  methods: {
    async handleSaveTemplate() {
      try {
        await this.$handlePrivilege("performance", "editTemplateUsage");
        this.organizeTemplate();
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    selectedFunction(val) {
      const disableIndex = this.functions
        .map((item) => item.id)
        .indexOf(val);

      document
        .querySelectorAll("select")[3]
        .options[disableIndex + 1].setAttribute("disabled", true);

      if (val !== "") {
        this.getDesignations(val);
        this.applyToAllDesignation = false;

        this.disableCheckBox = true;
      }
    },

    setApply(value) {
      this.apply = value;

      if (value === "all") {
        this.performanceTemplate.appliedTemplateTo.applyToAllJobLevel = true;
        this.performanceTemplate.appliedTemplateTo.applyToAllFunction = true;
        this.performanceTemplate.appliedTemplateTo.applyToAllLocation = true;
        this.performanceTemplate.appliedTemplateTo.applyToAllDesignation = true;
      } else {
        this.performanceTemplate.appliedTemplateTo.applyToAllJobLevel = false;
        this.performanceTemplate.appliedTemplateTo.applyToAllFunction = false;
        this.performanceTemplate.appliedTemplateTo.applyToAllLocation = false;
        this.performanceTemplate.appliedTemplateTo.applyToAllDesignation = false;
      }
    },

    addAppraisal() {
      const index = this.appraisal_cycles.length;
      this.appraisal_cycles.push({
        name: "Another Appraisal",
        label: `Appraisal ${index + 1}`,
        checkName: true,
        appraisal_starts: null,
        appraisal_ends: null,
        weight: null,
      });
    },

    validateWeight(w) {
      if (w < 0) {
        return `Value must be greater than or equal to 0`;
      }
      if (w > 0) {
        return this.watchWeightMax();
      }

      return true;
    },

    watchWeightMax() {
      let sum = 0;
      this.appraisal_cycles.map((cycle) => {
        const cycleConst = cycle;

        cycleConst.weight = Number(cycle.weight);

        sum += cycleConst.weight;
        return {};
      });

      if (sum > 100) {
        return "Total weight must not be greater than 100%";
      }

      return true;
    },

    getCycleName(type, index) {
      if (type === 2) {
        return this.BiAppraisal[index].value;
      }

      if (type === 4) {
        return this.QAppraisal[index].value;
      }

      return null;
    },

    getCycleLabel(type, index) {
      if (type === 2) {
        return this.BiAppraisal[index].label;
      }

      if (type === 4) {
        return this.QAppraisal[index].label;
      }

      if (type === 5) {
        return `Appraisal ${index + 1}`;
      }

      return null;
    },

    setAppraisalFreq(value) {
      if (value === "") {
        this.frequency = "1";
      } else {
        this.optionsPeriod.filter((freq) => {
          if (value === freq.name) {
            this.frequency = freq.id.toString();
            this.payloadFrequency = freq.id.toString();
          }
          return {};
        });
      }
    },

    organizeTemplate(type) {
      this.performanceTemplate.kpa_types = [];
      this.performanceTemplate.appliedTemplateTo.locationIds = [];
      this.performanceTemplate.appliedTemplateTo.jobLevelIds = [];
      this.performanceTemplate.appliedTemplateTo.functionIds = [];
      this.performanceTemplate.appliedTemplateTo.designationIds = [];

      delete this.performanceTemplate.performance_agreement;

      this.selectedKPATags.map((selected) => {
        this.performanceTemplate.kpa_types.push(selected.id);
        return {};
      });

      if (!this.performanceTemplate.appliedTemplateTo.applyToAllLocation) {
        this.selectedLocationTags.map((selected) => {
          this.performanceTemplate.appliedTemplateTo.locationIds.push(
            selected.id
          );
          return {};
        });
      }

      if (!this.performanceTemplate.appliedTemplateTo.applyToAllJobLevel) {
        this.selectedLevelTags.map((selected) => {
          this.performanceTemplate.appliedTemplateTo.jobLevelIds.push(
            selected.id
          );
          return {};
        });
      }

      if (!this.performanceTemplate.appliedTemplateTo.applyToAllFunction) {
        this.selectedFunctionTags.map((selected) => {
          this.performanceTemplate.appliedTemplateTo.functionIds.push(
            selected.id
          );
          return {};
        });

        if (this.performanceTemplate.appliedTemplateTo.applyToAllDesignation) {
          this.designations.map((selected) => {
            this.performanceTemplate.appliedTemplateTo.designationIds.push(
              selected.id
            );
            return {};
          });
        }
      }

      if (!this.performanceTemplate.appliedTemplateTo.applyToAllDesignation) {
        this.selectedDesignationTags.map((selected) => {
          this.performanceTemplate.appliedTemplateTo.designationIds.push(
            selected.id
          );
          return {};
        });
      }

      if (type === "draft") {
        this.saveDraft();
      } else {
        this.saveTemplate();
      }
    },

    saveTemplate() {
      this.performanceTemplate.performance_template_status = "active";
      this.performanceTemplate.status = "active";
      this.performanceTemplate.orgId = this.$orgId;
      this.performanceTemplate.title = this.performanceTemplate.agreement_title;
      this.performanceTemplate.year = new Date().getFullYear();

      this.$_createPerfomanceTemplate(this.performanceTemplate)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.push({
            name: "PerformanceSettings",
            query: { activeTab: "Performance Templates" },
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
        });
    },

    saveDraft() {
      this.performanceTemplate.performance_template_status = "draft";
      this.performanceTemplate.orgId = this.$orgId;
      this.performanceTemplate.title = this.performanceTemplate.agreement_title;

      if (this.performanceTemplate.appraisalCycleId !== "") {
        this.$_createPerfomanceTemplate(this.performanceTemplate)
          .then(() => {
            this.$toasted.success("Performance Template saved as Draft", {
              duration: 5000,
            });
            this.$router.push({
              name: "PerformanceSettings",
              query: { activeTab: "Performance Templates" },
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, { duration: 5000 });
          });
      }
      else {
            this.$toasted.error("You do not have an active Appraisal Cycle", { duration: 5000 });
      }
    },

    deleteSelectedDesignation(id) {
      const optionsIndex = this.designations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedDesignationTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedDesignationTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[5]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedDesignationTags.length === 0) {
        this.selectedDesignation = "";
      }
    },

    deleteSelectedFunction(id) {
      const optionsIndex = this.functions.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedFunctionTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedFunctionTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[3]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedFunctionTags.length === 0) {
        this.selectedFunctionResponse = "";
        this.designations = [];
      } else {
        this.allDesignations.filter((designation) => {
          if (id === designation.functionId) {
            this.deleteSelectedDesignation(designation.id);
          }
          return {};
        });

        this.designations = [];
        this.selectedFunctionTags.forEach((tag) => {
          this.getDesignations(tag.id);
        });
      }
    },

    deleteSelectedLevel(id) {
      const optionsIndex = this.jobLevels.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedLevelTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedLevelTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[4]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedLevelTags.length === 0) {
        this.selectedLevel = "";
      }
    },

    deleteSelectedLocation(id) {
      const optionsIndex = this.locations.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedLocationTags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedLocationTags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[2]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedLocationTags.length === 0) {
        this.selectedLocation = "";
      }
    },

    deleteSelectedKPA(id) {
      const optionsIndex = this.optionsKPA.map((item) => item.id).indexOf(id);
      const selectedIndex = this.selectedKPATags
        .map((item) => item.id)
        .indexOf(id);

      this.selectedKPATags.splice(selectedIndex, 1);
      document
        .querySelectorAll("select")[1]
        .options[optionsIndex + 1].removeAttribute("disabled");

      if (this.selectedKPATags.length === 0) {
        this.selectedKPA = "";
      }
    },

    normalizeKPA(kpaTypes) {
      this.kpaTypes = kpaTypes;
      this.optionsKPA = [];

      kpaTypes.filter((kpa) => {
        this.optionsKPA.push({
          id: kpa.id,
          name: kpa.name,
        });
        return {};
      });
    },

    getKPATypes() {
      this.$_getKPAtypes().then((response) => {
        const kpaTypes = response.data.KpaType;
        this.normalizeKPA(kpaTypes);
      });
    },

    getLocation() {
      this.$_getHumanarLocations("").then((response) => {
        this.locations = [];
        response.data.outlets.forEach((outlet) => {
          this.locations.push({
            id: outlet.id,
            name: outlet.name,
          });
        });
      });
    },

    getJobLevels() {
      this.$_getLevels().then((response) => {
        this.jobLevels = [];
        response.data.levels.forEach((level) => {
          this.jobLevels.push({
            id: level.id,
            name: level.name,
          });
        });
      });
    },

    getFunctions() {
      this.functions = [];
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    getDesignations(id) {
      this.$_getDesignations("").then((response) => {
        if (id !== "") {
          response.data.designations.filter((designation) => {
            if (id === designation.functionId) {
              this.designations.push({
                id: designation.id,
                name: designation.name,
              });
            }
            return {};
          });
        } else {
          this.designations = [];
          response.data.designations.filter((designation) => {
            this.designations.push({
              id: designation.id,
              name: designation.name,
            });
            return {};
          });
        }

        this.allDesignations = response.data.designations;
      });
    },

    getTemplate() {
      let performanceResponse = [];

      if (this.$route.params.id !== "new") {
        this.$_getOneTemplate(this.$route.params.id).then((response) => {
          performanceResponse = response.data.PerformanceTemplate;

          this.performanceTemplate.templateId = this.$route.params.id;
          this.performanceTemplate.status = performanceResponse.status;

          if (performanceResponse.appliedToPerormance[0].id) {
            this.performanceTemplate.appliedTemplateTo.appliedToId =
              performanceResponse.appliedToPerormance[0].id;
          }

          this.performanceTemplate.title = performanceResponse.title;
          this.performanceTemplate.enable_moderation =
            performanceResponse.enable_moderation;

          performanceResponse.kpa_types.map((kpa) => {
            this.timer = setTimeout(() => {
              this.selectedKPA = kpa.id;
            }, 1000);
            return {};
          });

          this.ceoStart = performanceResponse.ceo_moderation_start ? this.setDates(
            performanceResponse.ceo_moderation_start
          ) : null;
          this.ceoEnd = performanceResponse.ceo_moderation_end ? this.setDates(performanceResponse.ceo_moderation_end) : null;
          this.functionalStart = performanceResponse.functional_moderation_start ? this.setDates(
            performanceResponse.functional_moderation_start
          ) : null;
          this.functionalEnd = performanceResponse.functional_moderation_end ? this.setDates(
            performanceResponse.functional_moderation_end
          ) : null;

          this.performanceTemplate.enable_moderation =
            performanceResponse.enable_moderation;

          this.performanceTemplate.agreement_title =
            performanceResponse.agreement_title;
          this.performanceTemplate.agreement_description =
            performanceResponse.agreement_description;

          if (performanceResponse.appliedToPerormance[0]) {
            if (
              performanceResponse.appliedToPerormance[0]
                .applyToAllDesignation &&
              performanceResponse.appliedToPerormance[0].applyToAllJobLevel &&
              performanceResponse.appliedToPerormance[0].applyToAllFunction &&
              performanceResponse.appliedToPerormance[0].applyToAllLocation
            ) {
              this.applyTemplate = "all";
            } else {
              this.applyTemplate = "selected";
              this.performanceTemplate.appliedTemplateTo.applyToAllJobLevel =
                performanceResponse.appliedToPerormance[0].applyToAllJobLevel;
              this.performanceTemplate.appliedTemplateTo.applyToAllFunction =
                performanceResponse.appliedToPerormance[0].applyToAllFunction;
              this.performanceTemplate.appliedTemplateTo.applyToAllLocation =
                performanceResponse.appliedToPerormance[0].applyToAllLocation;
              this.performanceTemplate.appliedTemplateTo.applyToAllDesignation =
                performanceResponse.appliedToPerormance[0].applyToAllDesignation;
            }

            this.applyToAllLocation =
              performanceResponse.appliedToPerormance[0].applyToAllLocation;
            this.applyToAllJobLevel =
              performanceResponse.appliedToPerormance[0].applyToAllJobLevel;
            this.applyToAllFunction =
              performanceResponse.appliedToPerormance[0].applyToAllFunction;
            this.applyToAllDesignation =
              performanceResponse.appliedToPerormance[0].applyToAllDesignation;

            if (!this.applyToAllLocation) {
              performanceResponse.appliedToPerormance[0].locationIds.map(
                (location) => {
                  this.timer = setTimeout(() => {
                    this.selectedLocation = location;
                  }, 1000);
                  return {};
                }
              );
            }

            if (!this.applyToAllJobLevel) {
              performanceResponse.appliedToPerormance[0].jobLevelIds.map(
                (levels) => {
                  this.timer = setTimeout(() => {
                    this.selectedLevel = levels;
                  }, 1000);
                  return {};
                }
              );
            }

            if (!this.applyToAllFunction) {
              performanceResponse.appliedToPerormance[0].functionIds.map(
                (func) => {
                  this.timer = setTimeout(() => {
                    this.selectedFunctionResponse = func;
                  }, 1000);
                  return {};
                }
              );
            }

            if (!this.applyToAllDesignation) {
              performanceResponse.appliedToPerormance[0].designationIds.map(
                (designation) => {
                  const filtered = this.designations.filter(
                    (el) => el.id === designation
                  );

                  filtered.map((val) => {
                    this.filteredDesignations.push({
                      id: val.id,
                      name: val.name
                    });
                    return {};
                  })
                  return {};
                }
              );

              this.designations = this.filteredDesignations;

              performanceResponse.appliedToPerormance[0].designationIds.map(
                (designation) => {
                  this.timer = setTimeout(() => {
                    this.selectedDesignation = designation;
                  }, 2000);
                  return {};
                }
              );
            }
          }

          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },

    checkTemplateEmployeeApplication() {
      switch (this.applyTemplate) {
        case "selected":
          if (
            this.applyToAllLocation &&
            this.applyToAllJobLevel &&
            this.applyToAllFunction &&
            this.applyToAllDesignation
          ) {
            this.applyTemplate = "all";
          }
          break;
        default:
          break;
      }
    },

    moderationStart(value) {
      this.disabledDates.to = new Date(value.valueOf() + 1000 * 60 * 60 * 24);
      this.disableModerationDates.to = new Date(
        value.valueOf() + 1000 * 60 * 60 * 24
      );
    },

    moderationEnd(value) {
      this.disabledDates.to = new Date(value.valueOf() + 1000 * 60 * 60 * 24);
      this.disableModerationDates.to = new Date(
        value.valueOf() + 1000 * 60 * 60 * 24
      );
    },

    formatCycleDate(value) {
      const startDate = `${value}/${new Date().getFullYear()}`;

      const [mm, dd, yyyy] = startDate.split("/");

      return new Date(`${dd}-${mm}-${yyyy}`).toISOString();
    },

    getAppraisalCycle() {

      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        if (appraisal[0].status !== 'draft') {
          this.cycleStartDate = this.setDates(appraisal[0].cycle_start_date);
          this.cycleEndDate = this.setDates(appraisal[0].cycle_end_date);
          this.setAppraisalFreq(appraisal[0].appraisal_frequency);

          this.performanceTemplate.appraisalCycleId = appraisal[0].id;

          if (appraisal[0].cycles.length > 0) {
            this.performanceTemplate.performance_agreement.appraisal_cycles =
              appraisal[0].cycles;

            let endDate = "";

            appraisal[0].cycles.map((cycle) => {
              endDate = cycle.appraisal_ends;
              return {};
            });

            this.appraisalEndDate = new Date(this.formatCycleDate(endDate));

            this.disabledDates.to = new Date(
              this.appraisalEndDate.valueOf() + 1000 * 60 * 60 * 24
            );
            this.disableModerationDates.to = new Date(
              this.appraisalEndDate.valueOf() + 1000 * 60 * 60 * 24
            );

            this.performanceTemplate.performance_agreement.appraisalCycleId =
              appraisal[0].id;
          }
        }
      });
    },

    handleBreadcrumbClick(event) {
      this.$router.push({
        name: event,
      });
    },
  },

  mounted() {
    this.getTemplate();
    this.getKPATypes();
    this.getLocation();
    this.getJobLevels();
    this.getFunctions();
    this.getAppraisalCycle();
    if (this.$route.params.id !== "new") {
      this.getDesignations("");
    }
  },
};
</script>

<style>
.label label {
  display: none;
}

.bg-disabled {
  background-color: #eeeeee;
  color: #878e99;
}
</style>
